<template>
  <div class="w-100">
    <vue-slider
      class="py-4 my-3"
      v-model="timeRange"
      v-bind="sliderParams"
      @dragging="rangeDragEnd"
    ></vue-slider>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import isEqual from 'lodash/isEqual';
import VueSlider from 'vue-slider-component';

export default {
  mixins: [common],
  components: {
    VueSlider,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    minTimeRange: {
      type: Number,
      required: true,
      validator(v) {
        return v >= 0 && v <= 24;
      },
    },
    maxTimeRange: {
      type: Number,
      required: true,
      validator(v) {
        return v >= 1 && v <= 25;
      },
    },
  },
  data() {
    return {
      maxMinErrorShowed: false, /** changing by manual method */
    };
  },
  computed: {
    ...mapState(['strings']),
    sliderParams() {
      return {
        tooltip: 'always',
        'drag-on-click': true,
        max: 24,
        marks: [0, 24],
        contained: true,
        minRange: this.minTimeRange,
        maxRange: this.maxTimeRange,
      };
    },
    timeRange: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', this.validatedHours(...val));
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        const validated = this.validatedHours(value[0], value[1]);
        if (!isEqual(value, validated)) {
          this.timeRange = validated;
        }
      },
    },
  },
  methods: {
    /** reset modal popup */
    resetModal() {
      this.maxMinErrorShowed = false;
    },
    /** range slider on drag end handler */
    rangeDragEnd() {
      const v = this.timeRange;
      if (!this.maxMinErrorShowed) {
        if (Math.abs(v[1] - v[0]) > 0 && Math.abs(v[1] - v[0]) <= this.minTimeRange) {
          this.showRangeModal();
        } else if (Math.abs(v[1] - v[0]) >= this.maxTimeRange) {
          this.showRangeModal();
        }
      }
    },
    showRangeModal() {
      this.maxMinErrorShowed = true; /** disable next show modal */
      const message = this.format(
        this.strings.commonApp?.error_max_min_time_range,
        this.minTimeRange,
        this.maxTimeRange,
      );
      const modal = {
        id: 'wet-range-min-max-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-range-min-max-ok',
            title: this.strings.commonApp?.ok,
            event: 'close',
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    validatedHours(from = 0, to = 24) {
      let f = from;
      let t = to;
      if (from > to) t = from;
      f = (24 - from) <= this.minTimeRange ? 24 - this.minTimeRange : from;

      if ((to - f) >= this.minTimeRange) {
        t = (to - f) > this.maxTimeRange ? f + this.maxTimeRange : to;
      } else t = f + this.minTimeRange;
      return [f, t];
    },
  },
};
</script>

<style lang="scss">

</style>
